.modal .modal-dialog .modal-content .bvModalHeader .custom-close-block .bvModalCloseButton {
  top: 0;
  right: 0;
}
.bvModalHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);

  .custom-close-block {
    display: flex;
    color: #1e6ec5;

    .bvModalCloseButton {
      width: 17px;
    }

    span {
      padding-left: 8px;
    }

    cursor: pointer;
  }
}

.bvModalTitle {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: normal;
  color: #343a40;
  width: 100%;
  margin-top: 8px;
}

.bvModalCloseButton {
  float: right;
  cursor: pointer;
}
.margin-right-10 {
  margin-right: 10px;
}
.modalSecondaryButton {
  // width: 78px;
  // height: 38px;

  // width and height afftect the button text fold to next line
  // if required add padding top and bottom for set height
  margin: 0px 14px;
  border-radius: 4px;
  margin-right: 9.5px !important;
  border: solid 1px #1e6ec5;
  background-color: #ffff;
  text-align: center;
  color: #0275d8;
}

.tertiaryButtonContainer {
  .modalTertiaryButton  {
  border: solid 1px #1e6ec5;
  background-color: #ffff;
  text-align: center;
  border-radius: 4px;
  text-align: center;
  //Space Between Cancel and Set Buttons
  margin-right: 363px;
  min-width: 72px;
  padding: 1px 12px;
  color: #0275d8;
  }
}

.modalSecondaryButton:focus .modalTertiaryButton:focus {
  outline: 1px solid;
}

.modalPrimaryButton {
  height: 38px;
  margin: 0 14px;
  border-radius: 4px;
}

.bvModalButtonContainer {
  display: flex;
  justify-content: center;
  .tooltipContent {
    display: inline-block;
    .tooltipText {
      background-color: #1e6ec5;
      color: hsl(0, 0%, 100%);
      font-size: 14px;
      &:after {
        border-color: #1e6ec5 transparent transparent transparent;
      }
    }
  }
}
.attentionIcon {
  margin: 6px 0px 10px 5px;
}
.bvModalButtonContainer {
  button {
    margin: 0;
    margin-right: 8px;
    padding: 1px 12px;
    &:last-child {
      margin-left: 0;
    }
  }
}
