@import './assets/styles/base.scss';
@import './assets/styles/fonts.scss';

@import './assets/styles/main.scss';

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Open Sans', sans-serif !important;
  margin: 0;
  padding: 0;

  // hard overwriting styles tooltip if required will update2
  .tooltip {
    &.show {
      opacity: 1;
    }
    .tooltip-inner {
      background-color: #007bff;
      color: #fff;
    }
  }

  // hard overwriting styles tooltip if required will update!important
  .bs-tooltip-bottom {
    .arrow::before {
      border-bottom-color: #1e6ec5;
    }
  }
  // hard overwriting styles tooltip if required will update!important

  .bs-tooltip-top {
    .arrow::before {
      border-top-color: #1e6ec5;
    }
  }

  .version-tooltip {
    .version-popper-frags {
      opacity: 1;
      .tooltip-inner {
        border: 1px solid #1e6ec5;
        background-color: #fff !important;
        text-align: center;
        border-radius: 6px;
        color: #1e6ec5;
      }
    }
    .version-popper-style {
      opacity: 1;
      .tooltip-inner {
        background-color: #1e6ec5 !important;
        color: #fff !important;
      }
    }
  }
}
.version-popper-style[x-placement^='right'],
.version-popper-style[x-placement^='right'] .version-popper-frags[x-placement^='right'] {
  .arrow::before {
    border-right-color: #1e6ec5 !important;
  }
}

.version-popper-style[x-placement^='bottom'] {
  .arrow::before {
    border-bottom-color: #1e6ec5 !important;
  }
}

.version-popper-style[x-placement^='left'] {
  .arrow::before {
    border-left-color: #1e6ec5 !important;
  }
}

p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

// deep modal backdrop

.deep-backdrop {
  z-index: 1050;
}

// react-select dropdown scrollbar style
.css-1jknvbe-menu {
  .css-4ljt47-MenuList::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}
