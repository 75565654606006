@import '../core/core.index';

.bv-form-wrapper-multi-textfield {
  @extend %flex-col;
  .bv-form-multi-textfield-select__indicators {
    position: absolute;
    right: 15px;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
  }
}
.bv-form-multi-textfield {
  &-select {
    &__input {
      font-size: $text-md;
      color: $theme-text-color-shade-4;
    }
    &__multi-value__remove {
      position: relative;
      cursor: pointer;
      transition: $animation-duration3 $animation-type3;
      &:before {
        position: relative;
        top: -1px;
        content: url("data:image/svg+xml,%0A%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.53033 1.53033C7.82322 1.23744 7.82322 0.762563 7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L7.53033 1.53033ZM0.46967 6.46967C0.176777 6.76256 0.176777 7.23744 0.46967 7.53033C0.762563 7.82322 1.23744 7.82322 1.53033 7.53033L0.46967 6.46967ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033C7.82322 7.23744 7.82322 6.76256 7.53033 6.46967L6.46967 7.53033ZM6.46967 0.46967L0.46967 6.46967L1.53033 7.53033L7.53033 1.53033L6.46967 0.46967ZM0.46967 1.53033L6.46967 7.53033L7.53033 6.46967L1.53033 0.46967L0.46967 1.53033Z' fill='%2337373C'/%3E%3C/svg%3E%0A");
      }
      svg {
        display: none;
      }
    }
    &__indicators {
      .bv-form-multi-textfield-select__indicator {
        margin-right: -15px;
        cursor: pointer;
        display: block;
        &.bv-form-multi-textfield-select__clear-indicator {
          position: relative;
          &:before {
            flex-shrink: 0;
            width: 1em;
            height: 1em;
            content: url("data:image/svg+xml,%0A%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.1553 1.90533C10.4482 1.61244 10.4482 1.13756 10.1553 0.84467C9.86244 0.551777 9.38756 0.551777 9.09467 0.84467L10.1553 1.90533ZM0.84467 9.09467C0.551777 9.38756 0.551777 9.86244 0.84467 10.1553C1.13756 10.4482 1.61244 10.4482 1.90533 10.1553L0.84467 9.09467ZM1.90533 0.84467C1.61244 0.551777 1.13756 0.551777 0.84467 0.84467C0.551777 1.13756 0.551777 1.61244 0.84467 1.90533L1.90533 0.84467ZM9.09467 10.1553C9.38756 10.4482 9.86244 10.4482 10.1553 10.1553C10.4482 9.86244 10.4482 9.38756 10.1553 9.09467L9.09467 10.1553ZM9.09467 0.84467L0.84467 9.09467L1.90533 10.1553L10.1553 1.90533L9.09467 0.84467ZM0.84467 1.90533L9.09467 10.1553L10.1553 9.09467L1.90533 0.84467L0.84467 1.90533Z' fill='%2337373C'/%3E%3C/svg%3E%0A");
          }
          svg {
            display: none;
          }
        }
      }
    }
    &__control {
      border: 1px solid $theme-border-shade-4;
      border-radius: 4px;
      padding: 6px 23px 6px 10px;
    }
    &__value-container {
      &.bv-form-multi-textfield-select__value-container--is-multi {
        padding: 0;
        gap: 6px 4px;
        .bv-form-multi-textfield-select__multi-value {
          margin: 0;
          &.bv-form-multi-textfield-select__multi-value--is-disabled {
            .bv-form-multi-textfield-select__multi-value__remove {
              opacity: 0.7;
            }
          }
        }
        .bv-form-multi-textfield-select {
          &__multi-value {
            @supports not (display: grid) {
              margin: 0 4px 0 0;
            }
            .bv-form-multi-textfield-select__multi-value__remove {
              padding: 0px 6px;
            }
            &__label {
              padding: 4px 4px 4px 6px;
              font-size: $text-sm-plus;
              line-height: 1.3;
              color: $theme-text-color-shade-4;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
.bv-multi-textfield-view-only {
  margin-left: 16px;
  max-width: 308px;
  margin-top: 5px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      color: $theme-text-color-shade-1;
      font-size: $text-md;
      line-height: 1.5;
      font-weight: $font-weight-bold;
      + li {
        margin-top: 26px;
      }
    }
  }
}

.app-c-checkbox {
  @extend %flex-vert-center;
  cursor: pointer;
  margin: 0;
  max-width: fit-content;
  padding: 5px 0;
  input[type='checkbox'] {
    @extend %visually-hidden;
  }
  input:disabled {
    ~ .app-c-check-label {
      cursor: not-allowed;
    }
  }
  .field-na & input[type='checkbox']:disabled ~ .app-c-check-label {
    color: $theme-text-disabled;
  }

  .app-c-check-label {
    margin-left: 10.5px;
    font-size: $text-lg;
    line-height: 22px;
    color: $theme-text-color;
    align-items: flex-start;

    @extend %flex;
    &:before {
      flex-shrink: 0;
      content: '';
      border-radius: 2px;
      width: 1em;
      height: 1em;
      background-color: $component-bg;
      padding-bottom: 3px;
      margin-top: 0.15em;
      margin-right: 8px;
      @extend %flex-all-center;
      transition: background-color $animation-duration3;
      border: 1px solid;
      border-color: $theme-border-shade-6;
    }
  }
  input[type='checkbox']:disabled ~ .app-c-check-label {
    color: $theme-text-disabled;
    &:before {
      background-color: $theme-border-shade-1;
      border-color: $border-disabled;
    }
  }
  input[type='checkbox']:disabled:checked ~ .app-c-check-label:before {
    background-color: $btn-disabled;
    border-color: $btn-disabled;
  }
  input.can-enable[type='checkbox']:disabled ~ .app-c-check-label {
    color: $theme-text-color;
  }
  input.can-enable[type='checkbox']:disabled:checked ~ .app-c-check-label {
    &:before {
      background-color: $theme-text-disabled;
      border-color: $theme-text-disabled;
    }
  }

  input[type='checkbox']:checked ~ .app-c-check-label:before {
    background-color: $theme-blue-dark;
    border-color: $theme-blue-dark;
    content: url('data:image/svg+xml; utf8,<svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.58 7.50002L0 3.92002L1.56 2.36002L3.58 4.38002L7.94 0.0200195L9.5 1.58002L3.58 7.50002Z" fill="white"/>
        </svg>');
  }
}
