.navbar-logo {
  padding-left: 5px;
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande',
    sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.7;
  letter-spacing: normal;
  text-align: center;
  color: #0275d8;
  cursor: pointer;
}

.divider-block {
  height: 24px;
  border-right: 1px solid #9c9c9c;
}

body .navbar {
  width: 100%;
  height: 56px;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.navbar-icons {
  cursor: pointer;
  color: #1e6ec5;
}

.header-menu {
  display: flex;
  align-items: center;

  img {
    margin: 0px 16px;
    max-width: 30px;
  }

  .user-profile {
    max-width: auto;
    height: 32px;
    border: none;
    background: #ffffff;
    margin: 11px 0 11px 17px;
    border-radius: 4px;
    background-color: #4aad50;
  }

  .user-initials {
    width: auto;
    height: auto;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: #ffffff;
  }
}

@media only screen and (max-width: 600px) {
  .header-menu {
    display: none;
  }
}

.iconContainerNavBar {
  cursor: pointer;
  display: flex;
  min-width: 22px;
  img {
    margin: 0px;
  }
}

.unReadDot {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: #bb3b42;
}
