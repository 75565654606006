$base-theme: Bootstrap;
$skin-name: boot;
$swatch-name: Bootstrap;
$border-radius: 0.25rem;
$accent: #007bff;
$secondary: #e4e7eb;
$info: #17a2b8;
$success: #28a745;
$warning: #ffc107;
$error: #dc3545;
$body-bg: #ffffff;
$body-color: #292b2c;
$component-bg: #ffffff;
$component-color: #292b2c;
$card-cap-bg: #f7f7f9;
$card-cap-color: #292b2c;
$series-a: #0275d8;
$series-b: #5bc0de;
$series-c: #5cb85c;
$series-d: #f0ad4e;
$series-e: #e67d4a;
$series-f: #d9534f;

$theme-green: #28a745;
$theme-white: #fff;
$theme-black: #000;
$theme-blue-dark: #1e6ec5;
$theme-text-color: #373a3c;
$theme-text-disabled: #868e96;
$theme-text-color-shade-1: #4d4d4d;
$theme-text-color-shade-2: #6a7783;
$theme-text-color-shade-3: #6c757d;
$theme-text-color-shade-4: #37373c;
$theme-text-color-shade-5: #212529;
$theme-cyan-shade-1: #f0fafe;
$theme-border-shade-1: #e9ecef;
$theme-border-shade-2: #d3d4d6;
$theme-border-shade-3: #dddddd;
$theme-border-shade-4: #ced4da;
$theme-border-shade-5: #eceff1;
$theme-border-shade-6: #adb5bd;
$theme-border-shade-7: #e6e6e6;
$theme-border-shade-8: #dfe9f5;
$theme-border-shade-9: #80bdff;
$theme-border-shade-10: #f8f9fa;
$theme-border-shade-11: #f2f2f2;
$theme-border-shade-12: #e0e0d9;
$theme-border-shade-13: #fafafa;
$theme-border-shade-14: #eeeeee;
$theme-border-shade-15: #d4d4d4;

$orange: #ffa300;
$theme-bg-shade-1: #f8f8f8;
$theme-bg-shade-2: #c4c4c4;
$error2: #e00303;
$icon-primary-blue-light: #00a2e4;
$icon-primary-blue: $theme-blue-dark;
$icon-primary-blue-dark: #002b5c;
$primary-blue-dark: #075697;
$icon-primary-orange: #eaa439;
$icon-primary-lime: #98a031;
$icon-primary-red: #bb3b42;
$icon-primary-green: #4aad50;
$icon-accordion-caret: #1a2024;
$btn-disabled: #c0c0b2;
$border-disabled: #b0b8c0;
$border-green: #96a000;
$accent-hover: #0056b3;
$theme-accent-shade-1: #f2fafd;
$theme-accent-shade-2: #65ab5b;
$accent-bg-shade-1: #377cbf;
$dim-row: #f6f7f9;

//typography
$body-font: 'Open Sans', sans-serif;

// Font family
$font-primary: $body-font;
$font-secondary: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans',
  'Helvetica Neue', sans-serif;
$body-font: $font-primary, $font-secondary;

// Font weights
$font-weight-light: 200;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

// Font sizes
$text-xs: 10px; // 10px
$text-xs-plus: ($text-xs + 1); // 11px
$text-sm: ($text-xs + 2); // 12px
$text-sm-plus: ($text-xs + 3); // 13px
$text-md: ($text-xs + 4); // 14px
$text-md-plus: ($text-xs + 5); // 15px
$text-lg: ($text-xs + 6); // 16px
$text-lg-plus: ($text-xs + 7); // 17px
$text-xl: ($text-xs + 8); // 18px
$text-xl-plus: ($text-xs + 9); // 19px
$text-xxl: ($text-xs + 10); // 20px

$animation-type3: ease-in-out;
$animation-duration3: 0.3s;
$animation-duration1: 0.1s;
$animation-duration2: 0.2s;
