.defineActivity-header {
  // margin-top: 26px;
  // margin-left: 30px;
  // display: flex;
  height: 20%;
}

.defineActivity-title {
  flex-grow: 1;
  color: #4d4d4d;
  font-size: 20px;
  font-weight: 600;
}

.defineActivityInfoModal {
  font-size: 16px;
  color: #495057;
}

.defineActivity-select-discipline {
  margin-top: 12px;
  margin-left: 30px;
  width: 21%;
  position: absolute;
  right: 30px;
  top: 50px;
}

.defineActivity-link {
  margin-top: 5px;
  font-size: 16px;
  line-height: 0.88;
  text-align: center;
  display: flex;
  align-items: center;
}

.defineActivity-link-enabled {
  cursor: pointer;
  color: #0275d8;
}

.defineActivity-link-disabled {
  cursor: default;
  color: #c0c0b2;
}

.defineActivity-body {
  width: 100%;
  height: 85%;
}

.defineActivity-body-content {
  padding-top: 91px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #37373c;
}

.defineActivity-modal-header-image {
  margin-left: 8px;
}

.defineActivity-modal-table-container {
  margin-top: 33px;
  max-height: 24rem;
  overflow: auto;
  scroll-padding-top: 70px;
}

.defineActivity-thead th {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 10;
  background-color: white;
  box-shadow: inset 0 0.5px 0 #dfdfdf, inset 0 -0.5px 0 #dfdfdf;
}

.defineActivity-modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 0.9;
  color: #343a40;
}

.defineActivity-modal-table-header {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.13;
  color: #373a3c;
}

.defineActivity-table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #e2f5fb;
}

.defineActivity-table-cell-main {
  width: 40%;
}

.defineActivity-table-cell-side {
  width: 20%;
}

.defineActivity-select {
  padding: 0;
  margin-left: 30px;
  margin-top: 12px;
}
.genAct-dropdown {
  display: flex !important;
  .search-container {
    display: inline-block;
    margin-left: auto !important;
    margin-top: 10px !important;    .search-icon {
      cursor: pointer;
    }
    .search-input-container {
      position: relative;
      .search-input {
        width: 232px;
        height: 31px;
        background-image: url(../../../assets/common/search.svg);
        padding: 10px 28px 9px 33px;
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: 11px;
        border-radius: 4px;
        border: solid 1px #ced4da;
        font-size: 14px;
        line-height: 0.86;
        color: #343a40;
        transition: width 0.4s ease-in-out;
      }
      .search-input:focus {
        outline: none;
      }
      .clear-icon {
        position: relative;
        right: 25px;
        top: -2px;
        cursor: pointer;
      }
      .red {
        color: #ff0000;
      }
      
      .green {
        color: #4aad50;
      }
    }
  }
}

.defineActivity-pagination {
  display: flex;
  margin-top: 15px;
  justify-content: center;
}


.defineActivity-Container {
  height: 100%;
  overflow: auto;
}

.generateActivity-table-container {
  margin: 0 30px;
}

.generateActivity-table-tr > th:first-child {
  width: 30%;
}

.generateActivity-table-tr > th {
  width: 20%;
  vertical-align: middle;
}

.genetateActivityTableRow_notCompleted {
  background-color: #e2f5fb;
}

.genetateActivityTableRow_completed {
  background-color: #d4edda;
}

.genetateActivityTableRow-selectedActivityName {
  padding: 10px;
  height: 37px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  color: #343a40;
}

.genetateActivityTableRow-row > .genetateActivityTableRow-cell-main {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 36px;
  border: none;
  vertical-align: middle;
}

.genetateActivityTableRow-hr {
  margin: 0;
  margin-left: 36px;
  border-top: 1.5px dashed #dfdfdf;
}

.genetateActivityTableRow-row > .genetateActivityTableRow-cell-side {
  padding: 8px 12px;
  border: none;
  border-bottom: 1.5px dashed #dfdfdf;
}

.genetateActivityTableRow-row > td:first-child {
  width: 30%;
}

.genetateActivityTableRow-row > td {
  width: 10%;
}

.genetateActivityTableRow-cell-main {
  height: 37px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  color: #4d4d4d;
}

.genetateActivityTableRow-multiSelect {
  width: 100%;
  height: 100%;
  font-size: 16px;
  margin-bottom: 4px;
}

.genetateActivityTableRow-addRowButton {
  display: flex;
  justify-content: flex-start;
  margin: 12px 0 0 65px;
  padding-bottom: 10px;
  font-size: 14px;
  line-height: 1;
  color: #1e6ec5;
}

.genetateActivityTableRow-addRowButton-label {
  margin-left: 5px;
  vertical-align: middle;
}

.genetateActivityTableRow-select__indicator-separator {
  display: none;
}

.genetateActivityTableRow-select__indicators > .genetateActivityTableRow-select__indicator,
.genetateActivityTableRow-select__indicators > .genetateActivityTableRow-select__indicator:hover {
  color: #1e6ec5;
}

.genetateActivityTableRow-select__placeholder {
  font-size: 14px;
}

.generateActivity-table-header-label {
  display: flex;
}

.genetateActivityTableRow-cell-side:last-child {
  width: 1%;
  vertical-align: middle;
}

.generateActivity-table-header:last-child {
  width: 1%;
}

.genetateActivityTableRow-select__indicators > .genetateActivityTableRow-select__clear-indicator {
  display: none;
}

.genetateActivityTableRow-select__value-container .genetateActivityTableRow-select__multi-value {
  max-width: 80%;
  background-color: #28a745;
  border-radius: 5px;
  color: white;
  display: flex;
}
.row-deleted {
  .genetateActivityTableRow-select__value-container .genetateActivityTableRow-select__multi-value {
    background-color: hsl(0, 0%, 90%);
  }
  .genetateActivityTableRow-select__indicators {
    display: none;
  }
  .genetateActivityTableRow-select__control {
    border: none;
    background-color: inherit;
  }
  .genetateActivityTableRow-select__multi-value__label {
    color: #868e96 !important;
    padding-right: 6px;
  }
  .genetateActivityTableRow-select__multi-value__remove {
    display: none;
  }
}
.genetateActivityTableRow-select__value-container .genetateActivityTableRow-select__multi-value__label {
  color: white;
  font-weight: bold;
  flex-grow: 1;
}

.genetateActivityTableRow-select__indicators > .genetateActivityTableRow-select__dropdown-indicator {
  padding-left: 0;
}

.genetateActivityTableRow-select__control > .genetateActivityTableRow-select__value-container {
  padding-right: 0;
}

.genetateActivityTableRow-delete {
  cursor: pointer;
}

.generateActivity-table-container {
  max-height: 17.5rem;
  overflow: auto;
}

.generateActivity-table-thead th {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 10;
  background-color: white;
  box-shadow: inset 0 0.5px 0 #dfdfdf, inset 0 -0.5px 0 #dfdfdf;
}

.generateActivity-table-thead {
  border: none;
}

.tooltipContent .genetateActivityTableRow-input {
  margin: 0;
}

/* New Css Design */

.defineActivity_section1 {
  padding: 0 31px 12px 31px;
  display: flex;
  padding-top: 30px;
}

.defineActivity_section2 {
  padding: 0 31px;
}

.genAct {
  &-dropdown {
    height: 15%;
  }

  &-table {
    height: 85%;

    &-container {
      height: 100%;
    }

    &-frame {
      max-height: 85%;
      overflow: auto;
      border-top: 2px solid #e9ecef;
    }

    &-header {
      &-row {
        outline: 2px solid #e9ecef;
      }

      &-row > th {
        &:first-child {
          width: 30%;
          vertical-align: middle;
        }

        &:last-child {
          width: 24%;
        }

        width: 22%;
        vertical-align: middle !important;
      }

      &-container {
        display: flex;
        align-items: center;
        width: 90%;
      }

      &-label {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        color: #373a3c;
      }
    }

    &-body {
      &-firstRow {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.14;
        color: #343a40;

        &-completed {
          background-color: #d4edda;
        }

        &-notCompleted {
          background-color: #e2f5fb;
        }
      }

      &-row > td {
        max-width: 100px;
        border-bottom: 1.5px dashed #dfdfdf !important;

        &:first-child {
          padding-left: 36px;
          border: none !important;
        }

        &:last-child {
          vertical-align: middle;
        }

        &-column-delete {
          cursor: pointer;
        }
      }
    }
  }
}
.inactive-blocker-item {
  .genetateActivityTableRow-select {
    &--is-disabled {
      .genetateActivityTableRow-select__value-container .genetateActivityTableRow-select__multi-value {
        background: #c0c0b2;
        border-radius: 6px;
      }
      .genetateActivityTableRow-select__indicators > .genetateActivityTableRow-select__indicator,
      .genetateActivityTableRow-select__indicators > .genetateActivityTableRow-select__indicator:hover {
        color: #c0c0b2;
      }
    }
  }
}

.genAct-table-width-plus-20 {
  width: 120% !important;
}
.genAct-table-custom-code-width {
  min-width: 150px !important;
}