@import '../variables.scss';
@for $i from 8 through 50 {
  .icon-size-#{$i} {
    width: #{$i}px;
    height: #{$i}px;

    .icon-svg {
      @extend .icon-size-#{$i};
    }
  }

  .addon-size-#{$i} {
    .app-btn-addon {
      @extend .icon-size-#{$i};
    }
  }
}
svg {
  fill: transparent;
}
.icon-stroke {
  stroke: currentColor;
}

.icon-fill {
  fill: currentColor;
}
.icon-fill-white {
  fill: white;
}

//theme based icons

%theme-indicators {
  .icon-initiated {
    color: $icon-primary-blue;
  }
  .icon-pending {
    color: $icon-primary-orange;
  }
  .icon-assessment-progress {
    color: $icon-primary-lime;
  }
  .icon-assessment-completed,
  .icon-approved {
    color: $icon-primary-green;
  }
  .icon-awaiting {
    color: $icon-primary-blue-light;
  }
  .icon-changecomplete {
    color: $icon-primary-blue-dark;
  }
  .icon-inexecution {
    color: $icon-primary-blue;
  }
  .icon-not-approved {
    color: $icon-primary-red;
  }
}

.new-fg-1 {
  .custom-select-dropdown-scale--is-disabled {
    .custom-select-dropdown-scale__control--is-disabled {
      background-color: $theme-border-shade-1;
      border: 1px solid $theme-border-shade-4;
    }
  }
}

.radio-button-wrapper {
  @extend %flex-vert-center;
  @extend %flex-wrap;
  .radio-button-label {
    @extend %flex-vert-center;

    &.form-check-type-2 {
      min-width: 38px;
      .titleStyle {
        margin-left: 4px;
      }
      input {
        opacity: 0;
        position: absolute;
        ~ .radio-check-box {
          ~ .form-check-label {
            color: $theme-text-color-shade-3;
            font-size: $text-lg;
          }
        }
        &:disabled {
          ~ .radio-check-box {
            &.form-check-type-2 {
              border-color: $theme-border-shade-15;
              background-color: $theme-bg-shade-1;
            }
          }
        }

        &:checked {
          &:disabled {
            ~ .radio-check-box {
              border-color: $theme-bg-shade-2;
            }
          }
        }
      }
    }
    input {
      opacity: 0;
      position: absolute;
      &:checked {
        ~ .radio-check-box {
          background-color: $theme-white;
          border: 4px solid $theme-blue-dark;
        }
        &:disabled {
          cursor: not-allowed;
          ~ .radio-check-box {
            border-color: $theme-text-color-shade-1;
          }
          ~ .titleStyle {
            cursor: not-allowed;
          }
        }
      }
      ~ .radio-check-box {
        background-color: $theme-white;
        border: 1px solid $theme-border-shade-6;
        transition: $animation-duration3 $animation-type3;
      }
    }
    + .radio-button-label {
      margin-left: 30px;
    }
    .radio-check-box {
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }
    .titleStyle {
      margin-left: 10px;
      font-size: $text-md;
      line-height: 1.5;
      color: $theme-text-color-shade-4;
    }
  }
}
.no-data-bv {
  @extend %flex-all-center;
  width: 100%;
  text-align: center;
  @extend %flex-col;
  .no-data-bv-text {
    margin-top: 16px;
    color: $theme-text-color-shade-5;
    font-size: $text-lg;
    line-height: 1.375;
  }
}
%reset-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sr-only {
  @extend %visually-hidden;
}
