.quick-links-container {
  padding: 13px 21px 13px 14px;
  border-radius: 4px;
  height: 400px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #dddddd;
  background-color: #ffffff;
  .widget-header {
    padding-left: 0px;
    border-bottom: solid 1px #e9ecef;
    margin-bottom: 17px;
    display: flex;
    .widget-heading {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      margin-right: auto;
      line-height: 1.4;
      color: #4d4d4d;
    }
    .edit-icon {
      height: 16px;
      width: 16px;
      margin: 3px;
      cursor: pointer;
    }
  }
  .quick-links-content {
    margin-left: 4px;
    margin-right: 21px;
    .links {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.69;
      display: list-item;
      list-style-type: disc;
      list-style-position: inside;
      color: #1e6ec5;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .link-content {
        margin-left: -5px;
      }
    }
    .links::marker {
      color: #1e6ec5;
    }
    .empty-notice {
      height: 34px;
      font-size: 14px;
      font-weight: 600;
      color: #4d4d4d;
      line-height: normal;
      margin: auto;
      text-align: center;
      margin-top: 63px;
    }
  }
}
.quickLinksToolTip {
  .tooltip {
    opacity: 1 !important;
    .tooltip-inner {
      color: white;
      box-shadow: 0px 0px 5px 1px white;
      background-color: #1e6ec5;
    }
  }
  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto .arrow::before {
    border-top: 10px solid #1e6ec5;
  }
}

.edit-links-dialog {
  .bvModalTitle {
    font-size: 16px;
    color: #4d4d4d;
    line-height: 22px;
  }

  .bvModalCloseButton {
    margin: auto;
  }

  .bvModalHeader {
    padding: 13px 19px 8px 21px;
    border: none;
  }

  .modal-body {
    padding: 11px 10px 5px 10px;
    margin: 0 19px 0 14px;
    border-top: 1px solid #dee2e6;
  }
  .edit-links-content {
    .link-items-container {
      .link-items {
        min-height: 14px;
      }

      .item-row {
        .item-dropdown {
          display: inline-grid;
        }
        margin-bottom: 17px;
      }

      .field-label {
        font-size: 16px;
        font-weight: normal;
        font-style: italic;
        line-height: 1.5;
        color: #4d4d4d;
        margin-bottom: 12px;
        margin-right: 10px;
        &.link {
          margin-left: 19px;
        }
      }

      .delete-icon {
        margin-left: 20px;
        cursor: pointer;
      }

      .bv-text-field-container,
      .tooltipContent {
        display: inline-grid;

        .form-control {
          width: 170px;
          height: 31px;
          margin: 0px;
          font-size: 14px;
          font-weight: normal;
          line-height: 0.86;
          color: #6c757d;
        }
      }

      .librarySelect {
        width: 448px;
        display: inline-block;
        margin: 1px;

        .librarySelect__indicator,
        .librarySelect__indicator:hover {
          color: #1e6ec5;
        }

        .librarySelect__indicator-separator {
          display: none;
        }

        .librarySelect__single-value {
          color: #6c757d;
          font-size: 14px;
          line-height: 0.86;
        }

        .librarySelect__value-container {
          height: 31px;
          padding: 0 8px;
        }

        .librarySelect__indicators {
          height: 31px;
        }
      }
    }

    .add-fields {
      margin-bottom: 14px;
      .add-button {
        background: transparent;
        border: none;
        .add-icon {
          margin-right: 6px;
          width: 16px;
          height: 16px;
        }
        .add-label {
          font-size: 14px;
          font-weight: normal;
          line-height: 1;
          color: #1e6ec5;
        }
      }

      .add-button:disabled {
        .add-label {
          color: #c0c0b2;
        }
      }

      .create-library {
        margin-left: 20px;
      }
    }

    .footer-notice {
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
      line-height: 1.17;
      color: #6a7783;
    }
  }

  .modal-footer {
    padding: 10px 0 17.5px;
    margin: 0 19px 0 15px;
    .bvModalButtonContainer {
      margin: 0;
      .modalPrimaryButton {
        height: 31px;
        width: 55px;
        padding: 0 9px !important;
        margin: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 0.79;
        border-radius: 4px;
        background-color: #1e6ec5;
        border-color: #1e6ec5;
      }

      .modalPrimaryButton.disabled,
      .modalPrimaryButton:disabled {
        background-color: #c0c0b2;
        border-color: #c0c0b2;
        opacity: 1;
        cursor: default;
      }

      .modalSecondaryButton {
        margin: 0;
        border: none;
        height: 31px;
        padding: 0 9px !important;
        margin-right: 9.5px;
        font-size: 14px;
        font-weight: normal;
        line-height: 0.79;
      }
    }
  }
}

@media (min-width: 576px) {
  .modal-dialog.edit-links-dialog {
    max-width: fit-content;
  }
}
