.activitySelectContainer .activitySelect {
  width: 444px;
  height: 38px;
  font-size: 16px;
  display: inline-block;
  /* margin-bottom: 15px; */
  z-index: 11;
}

.activitySelectContainer .activityComplete {
  cursor: pointer;
  display: inline-block;
  margin-left: 30px;
  background: white;
  border: none;
  position: relative;
}

.activitySelectContainer .activityComplete:focus {
  outline: none;
}

.activitySelectContainer .completedStatus {
  display: inline-block;
  margin-left: 18px;
  font-size: 12px;
  line-height: 0.67;
}

.activitySelectContainer .completedStatus span {
  color: #4aad50;
}

.activitySelectContainer .selectOption {
  width: 100%;
  min-width: 395px;
  display: flex;
}

.activitySelectContainer .selectOption img {
  margin-top: 4px;
  margin-left: 5px;
}

.activitySelectContainer .selectOption span {
  flex-grow: 1;
}

.activitySelectContainer .activitySelect__single-value .selectOption img {
  background: white;
  position: absolute;
  right: 2px;
  padding: 0 2px;
}

.activitySelectContainer .activitySelect__input input {
  color: transparent !important;
}

.activitySelectContainer .circleBlue {
  border: 1px solid #2975c8;
  border-radius: 50%;
  position: absolute;
  transform: translateY(3px);
  width: 17px;
  height: 17px;
}

.activitySelectContainer .circleGrey {
  border: 1px solid #c0c0b2;
  border-radius: 50%;
  position: absolute;
  transform: translateY(3px);
  width: 17px;
  height: 17px;
}

.activityComplete .completeText {
  margin-left: 30px;
  color: #0275d8;
  font-size: 16px;
}

.activityComplete:disabled .completeText,
.activityComplete.disabled .completeText {
  color: #c0c0b2;
}

.activityComplete.activityEdit .completeText {
  margin-left: 14px;
}

.activityEdit img {
  margin-bottom: 5px;
}

.activitySelectContainer .activitySelect__indicator,
.activitySelectContainer .activitySelect__indicator:hover {
  color: #1e6ec5;
}

.activitySelectContainer .activitySelect__indicator-separator {
  display: none;
}

.activitySelectContainer .activitySelect__single-value {
  color: #1e6ec5;
  font-size: 16px;
}

.activitySelectContainer {
  display: flex;
}

.activitySelectContainer .activityComplete.dropdown-component-button,
.activitySelectContainer .activityComplete.activityEdit {
  margin-bottom: 15px;
  flex-shrink: 0;
}

.activitySelectContainer .completedStatus.dropdown-component-count {
  flex-shrink: 0;
}