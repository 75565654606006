.SideMenuListDiv {
  display: flex;
  width: 100%;
  min-height: 400px;
  overflow-x: hidden;
  /* height: 100vh; */
  height: calc(100vh - 80px);
}

.sidelistContainer {
  width: 25%;
  border-right: solid 1px #e9ecef;
  top: 233px;
  bottom: 79px;
  position: fixed;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  left: 0;
}

#impactedDisciplines {
  display: block !important;
}

.SideMenuListDiv .sidelistContainer > ul.list-group {
  height: auto;
  position: fixed;
  top: inherit;
  bottom: 243px;
  overflow: auto;
  width: inherit;
}
.SideMenuListDiv .sidelistContainer > ul.list-group::-webkit-scrollbar {
  width: 4px;
}

.SideMenuListDiv .sidelistContainer > ul.list-group::-webkit-scrollbar {
  width: 4px;
}

.SideMenuListDiv .sidelistContainer.noFooter {
  bottom: 0;
}

.SideMenuListDiv .sidelistContainer.noFooter > ul.list-group {
  bottom: 164px;
}

.SideMenuListDiv > ul > li {
  border-top: solid 1px #dddddd;
  border-bottom: solid 1px #dddddd;
  border-radius: 0px !important;
}

/* .SideMenuListDiv .sidelistContainer>ul.list-group:hover {
  overflow-y: auto;
} */
/* 
width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* ::-webkit-scrollbar:hover {
  width: 8px;
  height: 8px;
} */

/* scrollbar width
*:hover::-webkit-scrollbar:hover {
  width: 8px;
  height: 8px;
} */

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1e6ec5;
  border-radius: 4px;
}

.form-container {
  width: 75%;
  position: relative;
  margin-top: 232px;
  margin-left: 25%;
  /* margin-bottom: 79px; */
  overflow: auto;
  height: calc(100% - 232px);
}
/* .form-container:hover::-webkit-scrollbar{
  width: 8px;
  height: 8px;
} */

.p6MenuContainer {
  width: 75%;
  position: relative;
  margin-top: 232px;
  margin-left: 25%;
  margin-bottom: 20px;
  overflow: auto;
}

.noFooter + .form-container {
  margin-bottom: 0;
}
.track-change-textbox {
  box-shadow: 0 0 4px 1px #96a000;
  border: solid 1px #96a000 !important;
}

.track-change-indicator {
  border-radius: 1px;
  box-shadow: 0 0 4px 0 #96a000;
  border: solid 1px #96a000;
}

th {
  background: white;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.gig-checkbox-card-greyed-out {
  background-color: #e8e8e8;
}

.gig-workflow-title-greyed-out {
  color: grey !important;
}

@media only screen and (max-width: 767px) {
  .sidelistContainer {
    top: 244px;
  }

  .form-container {
    margin-top: 261px;
  }
}

.sidebar-image {
  margin-top: 20px;
  position: inherit;
  width: inherit;
  bottom: inherit;
}

.sidebar-image img {
  display: inline-block;
  width: 50%;
  vertical-align: bottom;
  max-width: 185px;
}

.SideMenuListLabel {
  font-size: 13px;
  line-height: 1.85;
  padding: 0 15px;
  margin: 0;
  color: #1e6ec5;
}

.sideMenuIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 18px;
  width: 17px;
  height: 17px;
}

.sideMenuIcon.circle-blue {
  border: 1px solid #2975c8;
  border-radius: 50%;
}

.sideMenuIcon.circle-white {
  border: 1px solid #edf2f7;
  border-radius: 50%;
}

.list-group-item.active .SideMenuListLabel {
  color: #ffffff;
}

.sidemenu-item {
  cursor: pointer;
}

.listHeading {
  font-size: 13px;
  line-height: 1.85;
  color: #002b5c;
  padding: 15px;
  text-transform: uppercase;
}

.subSectionTitleDiv {
  display: flex;
  align-items: center;
}

.subSectionTitleDiv .addField {
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  color: #1e6ec5;
}

.subSectionTitleDiv > p {
  font-size: 20px;
  font-weight: 600;
  color: #4d4d4d;
  padding-right: 10px;
}

.subSectionFieldTitle {
  font-size: 16px;
  color: #4d4d4d;
  display: inline-block;
}

.subSectionFieldValue {
  padding-left: 5px;
  font-size: 16px;
  color: #4d4d4d;
}

.editAdditional {
  cursor: pointer;
  margin-left: 10px;
}

.removeAdditional {
  float: right;
  cursor: pointer;
  margin-top: 5px;
  width: 14px;
}

.form-control.newField {
  border: 1px solid #28a745;
}

.completeButtonDisabled {
  width: 102px;
  height: 38px;
  margin: 0 10px;
  opacity: 0.5;
  border-radius: 4px;
  border: none;
  background-color: #c0c0b2;
  color: #ffff;
}

.completeButtonActive {
  width: 102px;
  height: 38px;
  border-radius: 4px;
  background-color: #28a745;
  margin: 0px 10px;
  border: none;
  color: white;
}

.save-button {
  width: 102px;
  height: 38px;
  border-radius: 4px;
  background-color: #28a745;
  margin: 0px 10px;
  border: none;
  color: white;
}

.reset-button {
  width: 102px;
  height: 38px;
  border-radius: 4px;
  border: solid 1px #f8f9fa;
  margin: 0px 10px;
  color: white;
  background: transparent;
}

.buttonDisabled {
  opacity: 0.5;
  background-color: #c0c0b2;
}
