// Override default variables before the import
$body-bg: #fff;

.btn {
  padding: 0 14px !important;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #e2f5fb;
}

.global-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.7;

  img {
    animation: spin 2s linear infinite;
  }

  z-index: 1000000000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// custom checkbox styles
.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid #adb5bd;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 1px;
  bottom: 1px;
  background-color: #adb5bd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider:before {
  background-color: white;
}

input:checked + .slider {
  background-color: #1e6ec5;
  border: 1px solid #1e6ec5;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

// replacing backdrop z-index
.plan-view-alert-backdrop {
  z-index: 15000;
}
.notify-modal-backdrop.show {
  z-index: 1050;
}
.unselectable {
  user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
