.dropdown-userProfile-container {
  width: auto;
  height: auto;
  padding: 19px 17px 7px 18px;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.31);
  border: solid 1px #dddddd;
  background-color: #ffffff;

  .user-initials-block {
    width: 62px;
    height: 52px;
    margin: auto;
    padding: 7px 7px 9px 6px;
    border-radius: 4px;
    background-color: #4aad50;

    .user-initials {
      width: auto;
      height: auto;
      font-size: 26px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.49px;
      text-align: center;
      color: #ffffff;
    }
  }

  .user-name {
    width: auto;
    height: auto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    color: #212529;
  }

  .user-email {
    width: auto;
    height: auto;
    margin: 0 0 24px 0px;
    padding: 0;
    line-height: 0.5;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #6c757d;
  }

  .line {
    width: auto;
    height: auto;
    margin: 8px 5px 2px 0;
    border: solid 1px #e9ecef;
  }

  .user-sign-out {
    width: auto;
    height: auto;
    padding: 0;
    margin: 2px 83px 0 0;
    border: none;
    background: #ffffff;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    color: #212529;
  }
}