.left-section {
  border-right: 1px solid #1e6ec5;
}

.engineering-execution {
  &.data-manager {
    .project-topbar-title {
      margin-bottom: 0px;
    }
  }
  .left-section {
    width: 284px;
  }
}

.project-header {
  width: 100%;
  position: fixed;
  top: 56px;
  z-index: 50;
}

.project-header.project-setup {
  .image-container {
    &:before {
      border-top: 1px solid #00a2e4;
    }
  }
  .slider-block {
    .nav-menu-link:after {
      border-top: 1px solid #00a2e4;
    }
  }
}

.project-topbar {
  color: #fff;
  line-height: 17px;
  background-image: linear-gradient(to left, #004578, #002652);
  padding: 18px 24px;

  .right-section {
    .row {
      flex-wrap: unset;
      overflow: hidden;
    }
  }

  .notificationContainer {
    position: relative;
    display: block;
    width: fit-content;
    margin: auto;
  }

  .notificationCount {
    color: #e9ecef;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    min-width: 22px;
    min-height: 22px;
    width: auto;
    height: auto;
    border: solid 2px #e9ecef;
    background-color: #bb3b42;
    border-radius: 50%;
    right: -8px;
    position: absolute;
    z-index: 10;
  }
}

.header-refernce-tooltip {
  .tooltip {
    opacity: 1 !important;
    .tooltip-inner {
      color: #fff;
      max-width: 350px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
      background-color: #007bff !important;
    }
    .arrow::before {
      border-bottom-color: #007bff !important;
      border-top-color: #007bff !important;
    }
  }
}

.header-tooltip {
  .tooltip {
    opacity: 1 !important;
    .tooltip-inner {
      color: black;
      max-width: 350px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
      background-color: white;
    }
    .arrow::before {
      border-bottom-color: white !important;
      border-top-color: white !important;
    }
  }
}

@media (min-width: 1440px) {
  .project-topbar {
    color: #fff;

    .right-section {
      .row {
        flex-wrap: nowrap;
        overflow: hidden;
      }
    }
  }
}

.project-step.col {
  padding: 0px;
  max-width: 130px;
}

.project-step {
  text-align: center;
  width: 120px;
  .col {
    width: 120px;
  }
}

.project-step a:hover {
  text-decoration: none;
}

.project-step a {
  color: #fff;
}

.project-step img {
  border-radius: 50%;
  margin-bottom: 5px;
  position: relative;
  width: 48px;
  height: 48px;
}

.project-step .step-title {
  color: #00a2e4;
  margin: auto;
  font-size: 14px;
  font-weight: 600;
  max-width: 84px;
  line-height: 20px;

  &.custom-step-title {
    width: 105px;
    max-width: unset;
  }

  &.large-title {
    width: 130px;
    max-width: unset;
  }
}

.project-step a.active .step-title {
  color: #e9ecef;
}

.project-step a.disable .step-title {
  color: #c0c0b2;
}

.project-topbar-title {
  font-size: 21px;
  margin-bottom: 22px;
}

.project-title,
.project-id {
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: 400;
}

.project-title {
  font-weight: 600;
}

.image-container {
  &:before {
    content: '';
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 0;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.nav-menu-link.disable .image-container img {
  filter: grayscale(100%);
}

.slider-block {
  .slick-arrow.slick-prev {
    left: -2%;
  }

  .project-step {
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .image-container {
    position: relative;
    &:before {
      content: none;
    }
  }

  .slick-slide:last-child {
    .nav-menu-link:after {
      content: none;
    }
  }

  .nav-menu-link:after {
    content: '';
    margin: 0 auto;
    position: absolute;
    top: calc(50% + 3px);
    left: 61%;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    transform: translateY(-50%);
  }
}

.project-step:first-child .image-container:before {
  width: 50%;
  left: unset;
}

.project-step:last-child .image-container:before {
  width: 50%;
  right: unset;
}
