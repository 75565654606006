@import './core/core.index';

%medium-button {
  font-size: $text-lg;
  padding: 8px 14px;
}

%large-button {
  font-size: $text-xxl;
  padding: 10px 14px;
}

//buttons
.btn-primary-dark,
.btn-primary-dark.btn-primary {
  background-color: $theme-blue-dark;

  &.primary-new {
    color: $white;
    padding: 8px 14px;

    &:hover:not(:disabled):not(.disabled) {
      background-color: rgba($color: $theme-blue-dark, $alpha: 0.7);
    }

    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }

  &:hover {
    text-decoration: none;
  }

  &:disabled,
  &.disabled {
    background-color: $btn-disabled;
    border-color: $btn-disabled;
    cursor: not-allowed !important;
  }
}

.btn-plain {
  color: $theme-blue-dark;
  background: transparent;

  &:disabled {
    color: $theme-text-disabled;
    cursor: not-allowed;
  }
}

.btn-default-bv {
  padding: 6.5px 14px;
  border-radius: 4px;
  border: 0;
  font-size: $text-md;
  transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s;

  &.btn-outline-bv {
    border: 1px solid $theme-blue-dark;
    color: $theme-blue-dark;
    background-color: transparent;

    &:hover:not(:disabled):not(.disabled) {
      border: 1px solid $icon-primary-blue-dark;
      color: $icon-primary-blue-dark;
      background-color: transparent;
    }

    &:disabled,
    &.disabled {
      border: 1px solid $btn-disabled;
      cursor: not-allowed;
      color: $theme-text-color-shade-3;
    }

    &:focus,
    &:active {
      border: 1px solid $icon-primary-blue-dark;
      color: $icon-primary-blue-dark;
      background-color: transparent;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      outline: none;
    }
  }

  &.no-outline-for-disabled.disabled:focus,
  &.no-outline-for-disabled[disabled]:focus {
    outline: 0 none;
    border: 1px solid $btn-disabled;
    box-shadow: none;
    color: $theme-text-color-shade-3;
  }
}

%btn-action {
  color: $theme-white;
  border-radius: 4px;
  border: 0;
  box-shadow: none;
  font-weight: $font-weight-regular;
  font-size: $text-lg;
  line-height: 1.375;
  padding: 8px 14px;
  transition: $animation-type3 $animation-duration3;

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.btn-primary-edit {
  @extend %btn-action;
  background-color: $icon-primary-green;

  &:disabled {
    background-color: $btn-disabled;
  }

  &.btn-d-type-2 {
    border: 1px solid $theme-blue-dark;
    color: $theme-blue-dark;
    background-color: transparent;

    &:hover:not(:disabled) {
      border: 1px solid $icon-primary-blue-dark;
      color: $icon-primary-blue-dark;
      background-color: transparent;
    }

    &:disabled {
      background-color: $theme-border-shade-12;
      color: $theme-white;
      border-color: $theme-border-shade-12;
    }
  }

  &:hover:not(:disabled) {
    background-color: rgba($color: $icon-primary-green, $alpha: 0.8);
  }
}

.btn-primary-reset {
  @extend %btn-action;
  background-color: transparent;
}

.btn-primary-cancel {
  @extend %btn-action;
  border: 1px solid $theme-white;
  background-color: transparent;

  &:disabled {
    border: 1px solid $btn-disabled;
    color: $btn-disabled;
  }

  &:hover:not(:disabled) {
    background-color: rgba($color: $theme-cyan-shade-1, $alpha: 0.2);
  }

  &.btn-default-bv {
    &:disabled {
      color: $white;
      background-color: $btn-disabled;
      border-color: $btn-disabled;
    }

    &:hover:not(:disabled) {
      background-color: rgba($color: $theme-cyan-shade-1, $alpha: 0.2);
    }
  }
}

.icon-btn {
  background-color: transparent;
  border: 0;
  transition: $animation-type3 $animation-duration3;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &:focus-visible {
    outline: 1px solid;
  }

  &:disabled {
    cursor: not-allowed;

    &.icon-btn--primary {
      svg {
        color: $theme-text-disabled;
      }
    }
  }

  &.icon-btn--primary {
    svg {
      transition: $animation-type3 $animation-duration3;
      color: $theme-blue-dark;
    }

    &:hover:not(:disabled) {
      svg {
        color: $icon-primary-blue-dark;
      }
    }
  }

  &.icon-btn--secondary {
    width: 28px;
    height: 26px;
    background-color: $theme-blue-dark;
    border-radius: 4px;

    svg {
      transition: $animation-type3 $animation-duration3;
      color: $white;
    }

    &:disabled,
    &.disabled {
      background-color: $theme-text-disabled;

      svg {
        color: $theme-border-shade-10;
      }
    }
  }
}

.edit-form {
  @extend %flex-vert-center;

  svg {
    margin-right: 8px;
  }

  .edit-form-title {
    color: $theme-blue-dark;
    transition: $animation-type3 $animation-duration3;
    font-size: $text-md;
  }

  &:hover:not(:disabled) {
    .edit-form-title {
      color: $icon-primary-blue-dark;
    }
  }

  &:disabled {
    .edit-form-title {
      color: $theme-text-disabled;
    }
  }
}

.edit-del-combo~div {
  display: inline-block;
}

//tooltip
.k-tooltip {
  &.theme-blue-dark {
    background: $theme-blue-dark;
    border-radius: 4px;

    &.mark-as-complete-width {
      max-width: 172px;
      text-align: center;
    }

    &.output-tooltip {
      padding: 10px 9px 10px 10px;
      max-width: 172px;
      text-align: center;
    }

    &.rework-tooltip {
      padding: 10px 9px 10px 10px;
      max-width: 172px;
      text-align: center;
    }

    &.manhour-tooltip {
      .k-tooltip-content {
        font-weight: $font-weight-semi-bold;
        text-align: center;
      }
    }

    &.ca-tooltip,
    &.gigref {
      .k-tooltip-content {
        font-weight: $font-weight-medium;
      }
    }

    &.define-act {
      padding: 5px 16px;

      .previous-values {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          position: relative;
          padding-left: 16px;

          &:before {
            content: '';
            position: absolute;
            width: 4px;
            height: 4px;
            top: 8px;
            left: 4px;
            border-radius: 50%;
            background-color: $theme-white;
          }
        }
      }
    }

    &.activity-act {
      padding: 2.5px 16px;
      max-width: 350px;
      font-weight: $font-weight-semi-bold;
      font-size: $text-md;
      line-height: 1.5;
    }

    .k-tooltip-content {
      font-weight: $font-weight-bold;
      font-size: $text-md;
      line-height: 19px;
    }

    .k-callout.k-callout {
      color: $theme-blue-dark;
    }
  }

  &.theme-gray {
    background: $theme-text-color-shade-2;
    border-radius: 4px;
    padding: 5px 11px;

    .k-tooltip-content {
      font-weight: $font-weight-bold;
      font-size: $text-sm;
      line-height: 16px;
    }

    .k-callout.k-callout {
      color: $theme-text-color-shade-2;
    }
  }
}

//dropdown
.bv-select-dropdown,
.bv-select-dropdown-medium {
  &--is-disabled {
    .bv-select-dropdown__control {
      &--is-disabled {
        background: $theme-border-shade-11;
        border-color: $theme-border-shade-4;
      }
    }

    .bv-select-dropdown__value-container--is-multi {
      .bv-select-dropdown__multi-value--is-disabled {
        background-color: $theme-border-shade-7;
      }
    }

    .bv-select-dropdown__value-container--is-multi {
      ~.bv-select-dropdown__indicators {
        .bv-select-dropdown__indicator {
          &.bv-select-dropdown__dropdown-indicator {
            &:before {
              content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' viewBox='0 0 8 5' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.19 0.0239999C0.228 0.00849993 0.2685 0.000499964 0.3085 0.000499964C0.349 0.000499964 0.3895 0.00849993 0.4265 0.0239999C0.464 0.0394999 0.498 0.0620001 0.5265 0.0910001L4 3.5655L7.474 0.0904999C7.5025 0.0619999 7.5365 0.039 7.574 0.0235C7.6115 0.00849997 7.6515 0 7.692 0C7.732 0 7.772 0.00799997 7.8095 0.0235C7.847 0.039 7.881 0.0619999 7.9095 0.0904999C7.9385 0.119 7.961 0.153 7.9765 0.1905C7.992 0.2275 8 0.268 8 0.308C8 0.3485 7.992 0.3885 7.9765 0.426C7.961 0.4635 7.9385 0.4975 7.9095 0.526L6.748 1.688L4.218 4.218C4.1895 4.2465 4.1555 4.2695 4.118 4.285C4.0805 4.3 4.0405 4.3085 4 4.3085C3.96 4.3085 3.9195 4.3005 3.882 4.285C3.8445 4.2695 3.811 4.2465 3.782 4.218L0.0905 0.5265C0.062 0.498 0.039 0.464 0.0235 0.4265C0.008 0.389 0 0.349 0 0.3085C0 0.2685 0.008 0.2285 0.0235 0.191C0.039 0.1535 0.062 0.1195 0.0905 0.0910001C0.119 0.0620001 0.153 0.0394999 0.1905 0.0239999H0.19Z' fill='%236C757D'/%3E%3C/svg%3E");
            }
          }
        }
      }
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    &:before {
      @extend %flex-vert-center;
      content: url('data:image/svg+xml,%0A%3Csvg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M0.285 0.574086C0.342 0.550836 0.40275 0.538836 0.46275 0.538836C0.5235 0.538836 0.58425 0.550836 0.63975 0.574086C0.696 0.597336 0.747 0.631086 0.78975 0.674586L6 5.88634L11.211 0.673836C11.2538 0.631086 11.3048 0.596586 11.361 0.573336C11.4173 0.550836 11.4773 0.538086 11.538 0.538086C11.598 0.538086 11.658 0.550086 11.7143 0.573336C11.7705 0.596586 11.8215 0.631086 11.8643 0.673836C11.9078 0.716586 11.9415 0.767586 11.9648 0.823836C11.988 0.879336 12 0.940086 12 1.00009C12 1.06084 11.988 1.12084 11.9648 1.17709C11.9415 1.23334 11.9078 1.28434 11.8643 1.32709L6.327 6.86509C6.28425 6.90784 6.23325 6.94234 6.177 6.96559C6.12075 6.98809 6.06075 7.00084 6 7.00084C5.94 7.00084 5.87925 6.98884 5.823 6.96559C5.76675 6.94234 5.7165 6.90784 5.673 6.86509L0.13575 1.32784C0.093 1.28509 0.0585 1.23409 0.03525 1.17784C0.012 1.12159 0 1.06159 0 1.00084C0 0.940836 0.012 0.880836 0.03525 0.824586C0.0585 0.768336 0.093 0.717336 0.13575 0.674586C0.1785 0.631086 0.22875 0.597336 0.285 0.574086Z" fill="%231E6EC5"/%3E%3C/svg%3E%0A');
    }

    svg {
      display: none;
    }
  }

  &__value-container--is-multi {
    .bv-select-dropdown__multi-value {
      border-radius: 2px;
      background-color: rgba($color: $theme-border-shade-7, $alpha: 0.4);

      .bv-select-dropdown__multi-value__label {
        font-size: $text-sm;
        color: $theme-text-color;
      }

      &__remove {
        position: relative;
        cursor: pointer;
        transition: $animation-duration3 $animation-type3;
        padding: 0 9px;

        &:before {
          position: relative;
          top: -1px;
          content: url("data:image/svg+xml,%0A%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.53033 1.53033C7.82322 1.23744 7.82322 0.762563 7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L7.53033 1.53033ZM0.46967 6.46967C0.176777 6.76256 0.176777 7.23744 0.46967 7.53033C0.762563 7.82322 1.23744 7.82322 1.53033 7.53033L0.46967 6.46967ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033C7.82322 7.23744 7.82322 6.76256 7.53033 6.46967L6.46967 7.53033ZM6.46967 0.46967L0.46967 6.46967L1.53033 7.53033L7.53033 1.53033L6.46967 0.46967ZM0.46967 1.53033L6.46967 7.53033L7.53033 6.46967L1.53033 0.46967L0.46967 1.53033Z' fill='%2337373C'/%3E%3C/svg%3E%0A");
        }

        svg {
          display: none;
        }
      }
    }

    ~.bv-select-dropdown__indicators {
      height: 100%;

      .bv-select-dropdown__indicator {
        height: 36px;

        &.bv-select-dropdown__dropdown-indicator {
          &:before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' viewBox='0 0 8 5' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.19 0.0239999C0.228 0.00849993 0.2685 0.000499964 0.3085 0.000499964C0.349 0.000499964 0.3895 0.00849993 0.4265 0.0239999C0.464 0.0394999 0.498 0.0620001 0.5265 0.0910001L4 3.5655L7.474 0.0904999C7.5025 0.0619999 7.5365 0.039 7.574 0.0235C7.6115 0.00849997 7.6515 0 7.692 0C7.732 0 7.772 0.00799997 7.8095 0.0235C7.847 0.039 7.881 0.0619999 7.9095 0.0904999C7.9385 0.119 7.961 0.153 7.9765 0.1905C7.992 0.2275 8 0.268 8 0.308C8 0.3485 7.992 0.3885 7.9765 0.426C7.961 0.4635 7.9385 0.4975 7.9095 0.526L6.748 1.688L4.218 4.218C4.1895 4.2465 4.1555 4.2695 4.118 4.285C4.0805 4.3 4.0405 4.3085 4 4.3085C3.96 4.3085 3.9195 4.3005 3.882 4.285C3.8445 4.2695 3.811 4.2465 3.782 4.218L0.0905 0.5265C0.062 0.498 0.039 0.464 0.0235 0.4265C0.008 0.389 0 0.349 0 0.3085C0 0.2685 0.008 0.2285 0.0235 0.191C0.039 0.1535 0.062 0.1195 0.0905 0.0910001C0.119 0.0620001 0.153 0.0394999 0.1905 0.0239999H0.19Z' fill='%234789d0'/%3E%3C/svg%3E");
          }
        }
      }

      .bv-select-dropdown__indicator-separator {
        background-color: $theme-border-shade-7;
        display: block;
      }

      .bv-select-dropdown__dropdown-indicator {
        &:before {
          position: relative;
          top: -1px;
        }
      }
    }
  }

  &__indicators {
    .bv-select-dropdown-medium__indicator {
      transition: $animation-type3 $animation-duration3;
    }
  }

  &__control {
    &.bv-select-dropdown-medium__control--is-focused {
      &.bv-select-dropdown-medium__control--menu-is-open {
        border-color: $theme-border-shade-9;
        box-shadow: 0 0 0 1px rgba($color: $theme-border-shade-9, $alpha: 0.25);
      }
    }
  }

  &__control--menu-is-open {

    .bv-select-dropdown-medium,
    .bv-select-dropdown {
      &__dropdown-indicator {
        transform: rotate(180deg);
        top: 1px;
        position: relative;
      }
    }
  }
}

.bv-select-dropdown__menu {
  background: $white;
  border: 1px solid $theme-border-shade-9;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px rgba(128, 189, 255, 0.25);
  border-radius: 4px;

  .bv-form-wrapper-multi-textfield {
    @extend %flex-col;

    .bv-form-multi-textfield-select__indicators {
      position: absolute;
      right: 15px;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .bv-select-dropdown__option {
    font-size: $text-sm;
    word-break: break-word;
    @extend %truncate;
    max-width: 100%;
    border-radius: 4px;
    min-height: 32px;
    cursor: pointer;
    max-width: calc(100% - 2px);
    margin: auto;
    transition: background-color $animation-duration1 $animation-type3;

    .selectOption-icon {
      @extend %flex;

      .ca-sideIcon {
        margin-left: auto;
        color: $theme-accent-shade-2;
      }
    }

    +.bv-select-dropdown__option {
      margin-top: 2px;
    }

    &--is-selected,
    .bv-select-dropdown__option--is-focused.bv-select-dropdown__option--is-selected {
      background-color: $theme-blue-dark;
      color: $white;

      .selectOption-icon {
        .ca-sideIcon {
          color: $theme-white;
        }
      }
    }

    &--is-focused:not(.bv-select-dropdown__option--is-selected) {
      background-color: $theme-border-shade-8;
    }
  }
}

.bv-select-dropdown-new {
  &--is-disabled {
    .icon-in-dropdown {
      color: $theme-text-disabled;
    }

    .bv-select-dropdown__control {
      &--is-disabled {
        background: $theme-border-shade-11;
        border-color: $theme-border-shade-4;
      }
    }

    .bv-select-dropdown__value-container--is-multi {
      .bv-select-dropdown__multi-value--is-disabled {
        background-color: $theme-border-shade-7;
      }
    }

    .bv-select-dropdown__value-container--is-multi {
      ~.bv-select-dropdown__indicators {
        .bv-select-dropdown__indicator {
          &.bv-select-dropdown__dropdown-indicator {
            &:before {
              content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' viewBox='0 0 8 5' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.19 0.0239999C0.228 0.00849993 0.2685 0.000499964 0.3085 0.000499964C0.349 0.000499964 0.3895 0.00849993 0.4265 0.0239999C0.464 0.0394999 0.498 0.0620001 0.5265 0.0910001L4 3.5655L7.474 0.0904999C7.5025 0.0619999 7.5365 0.039 7.574 0.0235C7.6115 0.00849997 7.6515 0 7.692 0C7.732 0 7.772 0.00799997 7.8095 0.0235C7.847 0.039 7.881 0.0619999 7.9095 0.0904999C7.9385 0.119 7.961 0.153 7.9765 0.1905C7.992 0.2275 8 0.268 8 0.308C8 0.3485 7.992 0.3885 7.9765 0.426C7.961 0.4635 7.9385 0.4975 7.9095 0.526L6.748 1.688L4.218 4.218C4.1895 4.2465 4.1555 4.2695 4.118 4.285C4.0805 4.3 4.0405 4.3085 4 4.3085C3.96 4.3085 3.9195 4.3005 3.882 4.285C3.8445 4.2695 3.811 4.2465 3.782 4.218L0.0905 0.5265C0.062 0.498 0.039 0.464 0.0235 0.4265C0.008 0.389 0 0.349 0 0.3085C0 0.2685 0.008 0.2285 0.0235 0.191C0.039 0.1535 0.062 0.1195 0.0905 0.0910001C0.119 0.0620001 0.153 0.0394999 0.1905 0.0239999H0.19Z' fill='%236C757D'/%3E%3C/svg%3E");
            }
          }
        }
      }
    }
  }

  // &__indicator-separator {
  //   display: none;
  // }
  &__single-value {
    font-size: $text-md;
  }

  &__dropdown-indicator {
    &:before {
      @extend %flex-vert-center;
      position: relative;
      top: -2px;
      height: 21px;
      content: url("data:image/svg+xml,%0A%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.19 0.523512C0.228 0.508012 0.2685 0.500012 0.3085 0.500012C0.349 0.500012 0.3895 0.508012 0.4265 0.523512C0.464 0.539012 0.498 0.561512 0.5265 0.590512L4 4.06501L7.474 0.590012C7.5025 0.561512 7.5365 0.538512 7.574 0.523012C7.6115 0.508012 7.6515 0.499512 7.692 0.499512C7.732 0.499512 7.772 0.507512 7.8095 0.523012C7.847 0.538512 7.881 0.561512 7.9095 0.590012C7.9385 0.618512 7.961 0.652512 7.9765 0.690012C7.992 0.727012 8 0.767512 8 0.807512C8 0.848012 7.992 0.888012 7.9765 0.925512C7.961 0.963012 7.9385 0.997012 7.9095 1.02551L6.748 2.18751L4.218 4.71751C4.1895 4.74601 4.1555 4.76901 4.118 4.78451C4.0805 4.79951 4.0405 4.80801 4 4.80801C3.96 4.80801 3.9195 4.80001 3.882 4.78451C3.8445 4.76901 3.811 4.74601 3.782 4.71751L0.0905 1.02601C0.062 0.997512 0.039 0.963512 0.0235 0.926012C0.008 0.888512 0 0.848512 0 0.808012C0 0.768012 0.008 0.728012 0.0235 0.690512C0.039 0.653012 0.062 0.619012 0.0905 0.590512C0.119 0.561512 0.153 0.539012 0.1905 0.523512H0.19Z' fill='%236C757D'/%3E%3C/svg%3E%0A");
    }

    svg {
      display: none;
    }
  }

  &__value-container--is-multi {
    .bv-select-dropdown__multi-value {
      border-radius: 0;
      background-color: rgba($color: $theme-border-shade-7, $alpha: 0.4);

      .bv-select-dropdown__multi-value__label {
        font-size: $text-sm;
        color: $theme-text-color;
      }

      &__remove {
        position: relative;
        cursor: pointer;
        transition: $animation-duration3 $animation-type3;
        padding: 0 9px;

        &:before {
          position: relative;
          top: -1px;
          content: url("data:image/svg+xml,%0A%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.53033 1.53033C7.82322 1.23744 7.82322 0.762563 7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L7.53033 1.53033ZM0.46967 6.46967C0.176777 6.76256 0.176777 7.23744 0.46967 7.53033C0.762563 7.82322 1.23744 7.82322 1.53033 7.53033L0.46967 6.46967ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033C7.82322 7.23744 7.82322 6.76256 7.53033 6.46967L6.46967 7.53033ZM6.46967 0.46967L0.46967 6.46967L1.53033 7.53033L7.53033 1.53033L6.46967 0.46967ZM0.46967 1.53033L6.46967 7.53033L7.53033 6.46967L1.53033 0.46967L0.46967 1.53033Z' fill='%2337373C'/%3E%3C/svg%3E%0A");
        }

        svg {
          display: none;
        }
      }
    }

    ~.bv-select-dropdown__indicators {
      height: 100%;

      .bv-select-dropdown__indicator {
        height: 36px;

        &.bv-select-dropdown__dropdown-indicator {
          &:before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' viewBox='0 0 8 5' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.19 0.0239999C0.228 0.00849993 0.2685 0.000499964 0.3085 0.000499964C0.349 0.000499964 0.3895 0.00849993 0.4265 0.0239999C0.464 0.0394999 0.498 0.0620001 0.5265 0.0910001L4 3.5655L7.474 0.0904999C7.5025 0.0619999 7.5365 0.039 7.574 0.0235C7.6115 0.00849997 7.6515 0 7.692 0C7.732 0 7.772 0.00799997 7.8095 0.0235C7.847 0.039 7.881 0.0619999 7.9095 0.0904999C7.9385 0.119 7.961 0.153 7.9765 0.1905C7.992 0.2275 8 0.268 8 0.308C8 0.3485 7.992 0.3885 7.9765 0.426C7.961 0.4635 7.9385 0.4975 7.9095 0.526L6.748 1.688L4.218 4.218C4.1895 4.2465 4.1555 4.2695 4.118 4.285C4.0805 4.3 4.0405 4.3085 4 4.3085C3.96 4.3085 3.9195 4.3005 3.882 4.285C3.8445 4.2695 3.811 4.2465 3.782 4.218L0.0905 0.5265C0.062 0.498 0.039 0.464 0.0235 0.4265C0.008 0.389 0 0.349 0 0.3085C0 0.2685 0.008 0.2285 0.0235 0.191C0.039 0.1535 0.062 0.1195 0.0905 0.0910001C0.119 0.0620001 0.153 0.0394999 0.1905 0.0239999H0.19Z' fill='%234789d0'/%3E%3C/svg%3E");
          }
        }
      }

      .bv-select-dropdown__indicator-separator {
        background-color: $theme-border-shade-7;
        display: block;
      }

      .bv-select-dropdown__dropdown-indicator {
        &:before {
          position: relative;
          top: -1px;
        }
      }
    }
  }

  &__indicators {
    .bv-select-dropdown__indicator-separator {
      background-color: $theme-border-shade-7;
      margin: auto;
      height: 14px;
    }
  }

  &__indicator {
    &.bv-select-dropdown__clear-indicator {
      max-height: 32px;
      align-items: center;

      svg {
        display: none;
      }

      &:before {
        content: url("data:image/svg+xml,%0A%3Csvg width='8' height='9' viewBox='0 0 8 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 1.5L1 7.5' stroke='%236C757D' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 1.5L7 7.5' stroke='%236C757D' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &__control {
    .bv-select-dropdown-new {
      &__indicator-separator {
        background-color: $theme-border-shade-7;
        max-height: 14px;
        margin-top: 12px;
      }
    }
  }
}

//dropdown medium
.bv-select-dropdown-medium {
  .bv-select-dropdown-medium__value-container--is-multi {
    ~.bv-select-dropdown__indicators {
      .bv-select-dropdown__indicator {
        &.bv-select-dropdown__dropdown-indicator {
          &:before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' viewBox='0 0 8 5' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.19 0.0239999C0.228 0.00849993 0.2685 0.000499964 0.3085 0.000499964C0.349 0.000499964 0.3895 0.00849993 0.4265 0.0239999C0.464 0.0394999 0.498 0.0620001 0.5265 0.0910001L4 3.5655L7.474 0.0904999C7.5025 0.0619999 7.5365 0.039 7.574 0.0235C7.6115 0.00849997 7.6515 0 7.692 0C7.732 0 7.772 0.00799997 7.8095 0.0235C7.847 0.039 7.881 0.0619999 7.9095 0.0904999C7.9385 0.119 7.961 0.153 7.9765 0.1905C7.992 0.2275 8 0.268 8 0.308C8 0.3485 7.992 0.3885 7.9765 0.426C7.961 0.4635 7.9385 0.4975 7.9095 0.526L6.748 1.688L4.218 4.218C4.1895 4.2465 4.1555 4.2695 4.118 4.285C4.0805 4.3 4.0405 4.3085 4 4.3085C3.96 4.3085 3.9195 4.3005 3.882 4.285C3.8445 4.2695 3.811 4.2465 3.782 4.218L0.0905 0.5265C0.062 0.498 0.039 0.464 0.0235 0.4265C0.008 0.389 0 0.349 0 0.3085C0 0.2685 0.008 0.2285 0.0235 0.191C0.039 0.1535 0.062 0.1195 0.0905 0.0910001C0.119 0.0620001 0.153 0.0394999 0.1905 0.0239999H0.19Z' fill='%236C757D'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  &__dropdown-indicator {
    &:before {
      @extend %flex-vert-center;
      content: url('data:image/svg+xml,%0A%3Csvg width="16" height="8" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M0.285 0.574086C0.342 0.550836 0.40275 0.538836 0.46275 0.538836C0.5235 0.538836 0.58425 0.550836 0.63975 0.574086C0.696 0.597336 0.747 0.631086 0.78975 0.674586L6 5.88634L11.211 0.673836C11.2538 0.631086 11.3048 0.596586 11.361 0.573336C11.4173 0.550836 11.4773 0.538086 11.538 0.538086C11.598 0.538086 11.658 0.550086 11.7143 0.573336C11.7705 0.596586 11.8215 0.631086 11.8643 0.673836C11.9078 0.716586 11.9415 0.767586 11.9648 0.823836C11.988 0.879336 12 0.940086 12 1.00009C12 1.06084 11.988 1.12084 11.9648 1.17709C11.9415 1.23334 11.9078 1.28434 11.8643 1.32709L6.327 6.86509C6.28425 6.90784 6.23325 6.94234 6.177 6.96559C6.12075 6.98809 6.06075 7.00084 6 7.00084C5.94 7.00084 5.87925 6.98884 5.823 6.96559C5.76675 6.94234 5.7165 6.90784 5.673 6.86509L0.13575 1.32784C0.093 1.28509 0.0585 1.23409 0.03525 1.17784C0.012 1.12159 0 1.06159 0 1.00084C0 0.940836 0.012 0.880836 0.03525 0.824586C0.0585 0.768336 0.093 0.717336 0.13575 0.674586C0.1785 0.631086 0.22875 0.597336 0.285 0.574086Z" fill="%231E6EC5"/%3E%3C/svg%3E%0A');
    }
  }
}

.bv-select-dropdown-medium__menu {
  .bv-select-dropdown-medium__option {
    font-size: $text-lg;
  }
}

//dropdown medium
.notification-wrapper {
  position: relative;

  .notificationBody {
    transition: height $animation-type3 $animation-duration3, margin-bottom $animation-type3 $animation-duration3;
  }

  .inline-loader {
    position: absolute;
    bottom: 21px;
    left: 18px;
    height: 0;
    pointer-events: none;
    transition: height $animation-type3 $animation-duration3, bottom $animation-type3 $animation-duration3;

    &.loading {
      height: 19px;
      bottom: 33px;

      ~.notificationBody {
        height: 300px;
        margin-bottom: 40px;
      }
    }
  }
}

.inline-loader {
  @extend %flex-all-center;

  .il-loader-wrapper {
    @extend %flex-all-center;
  }

  .il-loader {
    border: 3px solid $theme-border-shade-4;
    animation: spin 1s ease-out infinite;
    border-top: 3px solid $accent-bg-shade-1;
    border-right: 3px solid $accent-bg-shade-1;
    border-radius: 50%;
    width: 19px;
    height: 19px;
    border-left: 3px solid $accent-bg-shade-1;
    display: inline-block;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  .il-loading-text {
    margin-left: 4px;
    font-size: $text-sm;
    line-height: 1.3;
    font-weight: $font-weight-regular;
    color: $theme-text-color-shade-3;
  }
}

.vertical-middle {
  td {
    vertical-align: middle;
  }
}

.bv-custom-switch {
  .custom-control-input {
    &:checked {
      ~.custom-control-label {
        &:before {
          background-color: $theme-border-shade-9;
          border-color: $accent;
        }
      }
    }
  }
}

.k-animation-container-fixed {
  z-index: 1050;
}

.button-pack-group {
  @extend %flex;

  >button,
  >div {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

iframe[style*='fixed'] {
  display: none;
}

.overlay-coming-soon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: $theme-white, $alpha: 0.7);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  z-index: 3;
  @extend %flex-all-center;
}

.tab-main-section {
  &.data-empty {
    position: relative;

    //  padding-top: 56px;
    .overlay-coming-soon {
      box-shadow: none;
      left: -1px;
    }

    .assessment-dropdown {
      min-width: auto;
    }

    tr {
      td {
        height: 124px;

        &.arg-gigtypes-slide-col {
          &:last-child {
            width: 50%;
          }
        }
      }
    }
  }
}

.word-break-td {
  word-break: break-word;
}

.change-instance-action .btn-default-bv,
.fixed-inbtw-action-group .btn-default-bv,
.medium-button {
  @extend %medium-button;
}

.large-button {
  @extend %large-button;
}

//merge changes

.merge {
  &--dot {
    &:after {
      @extend %dotStyle;
      position: relative;
      top: 0;
      left: 3px;
    }

    &-sticky {
      position: relative;
      display: inline-block;

      &::after {
        @extend %dotStyle;
        top: 0;
        right: -16px;
        display: inline-block;
        position: absolute;

        .gig-navigation & {
          right: -10px;
          top: -1px;
        }
      }
    }
  }

  &--alert-block {
    position: relative;

    td:first-child:before {
      content: '';
      width: calc(100% - 4px);
      left: 2px;
      top: 0;
      height: 100%;
      position: absolute;
      border: 1px solid $orange;
      box-shadow: 0px 0px 4px 1px $orange;
      z-index: 3;
      pointer-events: none;
    }
  }

  &--selected-item {
    .genetateActivityTableRow-select__value-container .genetateActivityTableRow-select__multi-value {
      background-color: $orange;
    }
  }

  &--input-text {
    color: $orange;

    .bv-text-field-container,
    .form-control,
    .app-c-check-label {
      color: $orange  !important;
    }

    .app-c-check-label {
      &:before {
        border-color: $orange  !important;
      }
    }
  }

  &--select-item {

    .bv-select-dropdown__multi-value,
    .bv-multi-text__multi-value {
      background-color: $orange  !important;
    }
  }

  &--radio-block {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      box-shadow: 0px 0px 4px 1px $orange;
      top: -3px;
      left: 0;
    }

    color: initial;
  }

  &--border-active {

    .bv-select-dropdown__control,
    .form-control,
    .bv-multi-text__control {
      border-color: orange;
    }
  }
}

.track-changes {
  .bv-select-dropdown__control {
    border: 1px solid $border-green;
    box-shadow: 0px 0px 4px 1px $border-green;
    border-radius: 1px;
  }

  .div-track-changes {
    border: 1px solid $border-green;
    box-shadow: 0px 0px 4px 1px $border-green;
    border-radius: 1px;
    padding: 6px 12px;
    background-color: hsl(0, 0%, 95%);
    width: 100%;
    cursor: default;
    word-break: break-word;
  }

  .tooltipContent,
  .pad-wrapper {
    .form-control {
      border: 1px solid $border-green;
      box-shadow: 0px 0px 4px 1px $border-green;
      border-radius: 1px;
    }

    .bv-select-dropdown__control {
      border: 1px solid $border-green;
      box-shadow: 0px 0px 4px 1px $border-green;
      border-radius: 1px;
    }

    .genetateActivityTableRow-select {
      border: 1px solid $border-green;
      box-shadow: 0px 0px 4px 1px $border-green;
      border-radius: 1px;
    }

    // .errorTextBox {
    //   border: 1px solid $theme-border-shade-4;
    //   box-shadow: none;
    //   border-radius: 5px;
    // }
  }
}

.fade.modal-backdrop.no-backdrop.show {
  opacity: 0;
  pointer-events: none;
}

.full-viewModal-Breadcrumb {

  // padding: 0;
  &.modal {
    height: calc(100% - 56px);
    z-index: 999;
    top: 56px;

    .modal-content {
      padding: 0;
    }

    .modal-body {
      margin-top: 20px;
      padding: 0;
    }

    .bvModalHeader {
      padding: 13px 20px;
      background-color: $theme-border-shade-1;
    }
  }

  .modal-xl {
    .bread-margin.absolute {
      width: 100%;

      .bread-item:not(.bread-link),
      .bread-sep {
        color: $theme-text-color-shade-3;
      }

      button {
        &.bread-item {
          background: transparent;
          border: 0;
          padding: 0;
          color: $accent;

          &:hover {
            text-decoration: underline;
            color: $accent-hover;
          }
        }
      }

      .bread-item {
        &:not(.first-bread):before {
          content: '/';
          display: inline-block;
          margin: 0 5px;
        }

        &.bread-link {
          &:before {
            color: $accent;
          }

          &:hover:before {
            text-decoration: none;
          }
        }
      }

      .breadcrumb-back-btn {
        color: $theme-blue-dark;
        padding: 0;
        margin-right: 20px;
        @extend %flex-vert-center;

        .icon-back-toggle {
          margin-right: 10px;
        }
      }
    }
  }
}

.notification-with-header {
  ~.notifications-container {
    margin-top: 60px;
  }
}
.icon-btn.icon-btn--primary .icon-expand {
  color: $theme-black;
}