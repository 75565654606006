.projectActivityImportLabelText {
  width: 140px;
  height: 11px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.79;
  letter-spacing: normal;
  text-align: center;
  color: #1e6ec5;
}

.projectActivityImportButton {
  margin: 15px 10px;
  padding: 9px;
  border-radius: 4px;
  border: solid 1px #1e6ec5;
  display: inline;
  background: #fff;
  color: #1e6ec5;
  font-size: 14px;
}

.projectActivityHeaderTitle {
  font-size: 20px;
  font-weight: 600;
  color: #4d4d4d;
  margin-bottom: 12px;
}

.activitiesTable input.formInline {
  margin: 0;
  padding: 0;
  line-height: 1;
  width: 55px;
  height: 30px;
}

.activitiesTable input.formInline.dosActivity {
  width: 100%;
}

.manhrsContainer input.dosActivity {
  min-width: 250px;
}

.activitiesTable .formInline:disabled {
  background-color: transparent;
  border-color: transparent;
  -webkit-appearance: none;
}

.activitiesTable .table tbody td input:enabled {
  color: #1e6ec5;
}

.activitiesTable .table tbody td,
.activitiesTable .table tbody td input {
  font-size: 14px;
  text-align: center;
  margin: auto;
  vertical-align: middle;
}

.activitiesTable input::-webkit-outer-spin-button,
.activitiesTable input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.activitiesTable .table thead th {
  font-size: 16px;
  color: #373a3c;
  vertical-align: middle;
  text-align: center;
}

.activitiesTable .headcol {
  //min-width: 250px;
  border-right: 3px solid #d3d4d6;
  text-align: left !important;
}

.activitiesTable .headerContainer {
  display: flex;
  align-items: center;
}

.activitiesTable .firstRow {
  background-color: #00a2e40f;
}
// .activitiesTable:not(.completed) .firstRow:not(:first-child) {
//   min-width: 190px;
//}

.activitiesTable .firstRow:not(:first-child) {
  .hint-budget {
    display: inline-block;
    min-width: 135px;
  }
}
.activitiesTable.completed .firstRow {
  background-color: #28a74526;
}

.activitiesTable .firstcol {
  padding-left: 40px;
  font-size: 14px;
}

.activitiesTable .firstcol input {
  text-align: left;
}

.activitiesTable .showInvalid .tooltipContent {
  display: inline-block;
  position: absolute;
  width: 210px;
  font-size: 14px;
  font-weight: 400;
}

.activitiesTable .fontBold {
  font-weight: 700;
}

.activitiesTable .showInvalid {
  color: #d9534f;
}

.activitiesTable .showInvalid img {
  margin-bottom: 4px;
  margin-left: 10px;
  display: block;
}

/* .activitiesTable thead th img {
  position: absolute;
  top: 34%;
  transform: translateX(10px);
} */

.activitiesTable .tooltipContent .tooltipText.red {
  width: 220px;
  background-color: #bb3b42;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: -15px;
  left: 70%;
  bottom: unset;
}

.activitiesTable .tooltipContent .tooltipText.red::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  left: unset;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #bb3b42 transparent transparent;
}

.activitiesTable .emptyMessage {
  width: 540px;
  text-align: center;
  margin: auto;
  margin-top: 52px;
}
