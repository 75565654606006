.activityContainerBreadCrumb {
  width: 100%;
  // top: 243px;
  height: 60px;
  // position: fixed;
  z-index: 999;
  background-color: #fff;
  padding-top: 16px;
}

.activityContentBreadCrumb {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.activityPage > img {
  width: 24px;
  height: 22px;
  margin-right: 7.5px;
}

.activityBreadCrumb {
  margin-bottom: 0;
  list-style-type: none;
  padding: 0;
  display: flex;
  text-align: center;
}

.activityPage {
  display: flex;
  font-size: 16px;
}

.completeCircle {
  background: url('../../../assets/common/checkCircle.svg') no-repeat;
  width: 24px;
  height: 24px;
  background-size: cover;
  margin: 0 7.5px 0 0;
}

.numCircle {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 7.5px 0 0;
  background: #fff;
  border: 2px solid #1e6ec5;
  color: #1e6ec5;
  font-size: 14px;
}

.numCircleGray {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 7.5px 0 0;
  background: #fff;
  border: 2px solid #c0c0b2;
  color: #c0c0b2;
  font-size: 14px;
}

.fragmentStepsDetails {
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1e6ec5;
  margin-right: 26px;
}

.fragmentStepsDetailsGray {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #c0c0b2;
  margin-right: 26px;
}

.Line {
  width: 100%;
  height: 1px;
  background-color: #eceff1;
}

.underLine {
  height: 2px;
  margin-top: 18px;
  background-color: #1e6ec5;
}
