.dropdown-notification-menu {
  margin-right: 0px;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.32), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  .table {
    margin-bottom: 0px;
  }

  .notificationHeader {
    font-size: 16px;
    padding: 10px 0px 10px 15px;
    border-bottom: solid 1px #eceff1;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #343a40;
  }

  .notificationBody {
    overflow: auto;
    height: 340px;
  }

  .notificationRow {
    transform: translateX(310px);
    transition: all 0.75s ease;
  }

  .notificationBodyText {
    text-align: center;
    padding-top: 145px;
  }

  .notificationTableBody {
    font-size: 12px;
    font-stretch: normal;
    letter-spacing: normal;

    .dateAndTime {
      font-weight: bold;
      font-style: italic;
      line-height: 1.33;
      color: #343a40;

      span {
        margin-right: 10px;
        white-space: pre-wrap;
      }
    }

    .notificationImgContainer {
      display: flex;
    }

    .notificationArrow {
      width: 16px;
      height: 16px;
    }
  }

  .notificationViewAll {
    padding-top: 6px;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: #1e6ec5;
    border-top: solid 1px #eceff1;
    .disabledATag {
      pointer-events: none;
      color: #ccc;
    }
  }
}

.notificationUnReadDot {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: #bb3b42;
  margin-top: 6px;
}

.notificationImg {
  border-radius: 50%;
  margin-left: 5px;
  filter: drop-shadow(0px 1px 2px black);
}

.notifications-container {
  .outer-container {
    width: auto;
    height: auto;
    position: relative;
    padding: 20px 0 0 24px;
    background-color: #ffffff;
  }

  .heading {
    width: auto;
    height: auto;
    margin: 0 903.8px 22px 0;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d4d4d;
  }

  .project-container {
    display: block;
    width: auto;
    height: auto;
    margin: 9px 23px 0 0;

    .project-list {
      width: auto;
      height: auto;
      border: none;
      background: #ffffff;
      margin: 0 42px 0 0;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1e6ec5;
      overflow: hidden;
    }

    .isActive {
      border-bottom: solid 4px #1e6ec5;
    }
  }

  .inner-container {
    width: auto;
    height: auto;
    margin: 0px 23px 0 0;
    padding: 16px 23px 36px 21px;
    border-radius: 4px;
    border: solid 1px #dddddd;
    background-color: #ffffff;
  }

  .buttons {
    width: auto;
    height: auto;
    border: none;
    background: #ffffff;
    margin: 13px 0 41px 18px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: right;
    color: #0275d8;
    float: right;
  }

  .button:disabled,
  button[disabled] {
    color: gray;
  }

  .notifications-Table {
    overflow: auto;
    height: calc(100vh - 300px);
  }

  .notification-not-found {
    text-align: center;
    margin-top: 10rem;
  }

  .padding-0 {
    padding-right: 0;
    padding-left: 0;
  }

  table {
    border-collapse: collapse;
  }

  .row.no-gutter {
    margin-left: 0;
    margin-right: 0;
  }

  .row.no-gutter [class*='col-']:not(:first-child),
  .row.no-gutter [class*='col-']:not(:last-child) {
    padding-right: 0;
    padding-left: 0;
  }

  .row > div {
    background: lightgrey;
    border: 1px solid;
  }

  .container {
    padding: 2rem 0rem;
  }

  table tr:first-child td {
    border-top: 0;
  }

  table tr:last-child td {
    border-bottom: 1px solid #dddddd;
  }

  .checkbox {
    width: auto;
    height: auto;
    margin: auto;
    padding: 1px 0 0;
  }

  .oval {
    width: 6px;
    height: 6px;
    margin: auto;
    background-color: #bb3b42;
    border-radius: 50%;
    display: inline-block;
  }

  .date-and-time {
    width: auto;
    height: auto;
    margin: auto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #4d4d4d;
    span {
      white-space: pre-wrap;
    }
  }

  .notification-message {
    width: auto;
    height: auto;
    margin: auto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    color: #4d4d4d;
  }

  .notification-message .inner-text {
    font-weight: bold;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #0275d8;
  }

  .slick-disabled { opacity: 0% !important; pointer-events: none;  }

  .slick-track {
    margin-left: 0;
    margin-right: 0;
    display: flex !important;
    align-items: flex-end;
  }

  .slick-slide {
    height: auto;
    overflow-wrap: break-word;
    width: calc(20vw - 12px);
    flex-shrink: 0;
    box-sizing: border-box;
    padding-bottom: 5px;

    &>div { 
      display: flex;
      align-items: flex-start;
      justify-content: center;
      .oval {
        flex-shrink: 0;
        margin: 0;
        margin-top: 7px;
        margin-right: 4px;
      }
      button {
        display: inline-block;
        text-align: left
      }
    }
    
  }

  .slick-slider {
    margin: 0 10px;
  }
}
