.project-breadcrumb {
  padding: 13px 20px;
  background-color: #e9ecef;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.project-breadcrumb .home {
  color: #087ffe;
}
.absolute-change-form {
  padding-top: 50px !important;
}
.absolute-change-form .cf-header {
  position: fixed;
  top: 102px;
  width: 100%;
  z-index: 90;
  left: 0px;
  padding: 15px 24px;
  padding-top: 28px;
  background-color: white;
}
.breadcrumpabsolute {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;
}
.edit-form {
  margin-left: 20px;
}
.change-form {
}
