.dropdown-HelpPopup-container {
  width: auto;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.31);
  border: solid 1px #dddddd;
  background-color: white;

  .helpLinks-container {
    margin-top: 28px;
    margin-bottom: 21px;
  }

  .no-edit-icon {
    margin-top: 14px;
    margin-bottom: 21px;
  }

  .help-popup-links {
    width: auto;
    height: auto;
    padding: 0px 3px 0px 16px;
    margin: 10px 0px 10px 0px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #1e6ec5;
  }

  .empty-notice {
    width: auto;
    height: auto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d4d4d;
    text-align: center;
  }

  .edit-help-icon {
    float: right;
    margin-right: 9px;
    margin-top: 8px;
  }

  .help-link-tooltip {
    .tooltip {
      opacity: 1 !important;

      .tooltip-inner {
        color: white;
        box-shadow: 0px 0px 5px 1px white;
        background-color: #1e6ec5;
      }
    }

    .bs-tooltip-bottom .arrow::before,
    .bs-tooltip-auto .arrow::before {
      border-top: 10px solid #1e6ec5;
    }
  }
}