.pagination {
  .page-item {
    outline: none !important;
    box-shadow: none !important;
    a {
      outline: none !important;
      color: #1e6ec5;
      box-shadow: none !important;
    }
    &.disabled .page-link {
      background-color: #dee2e6;
    }
    &.show-count {
      font-weight: bold;
      color: #1e6ec5;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-top: 1px solid #dee2e6;
      border-bottom: 1px solid #dee2e6;
      min-width: 130px;
    }
  }
}

.page-number-label {
  padding-left: 15px;
  padding-top: 7px;
  padding-right: 7px;
}

.page-number-dropdown {
  width: 80px;
}
