%flex {
  display: flex;
}
%inline-flex-all-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
%flex-center {
  display: flex;
  justify-content: center;
}
%inline-flex-vert-center {
  display: inline-flex;
  align-items: center;
}

%flex-vert-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

%flex-col {
  display: flex;
  flex-direction: column;
}
%flex-row {
  display: flex;
  flex-direction: row;
}
%inline-flex-horiz-away-vert-center {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

%flex-all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
%flex-all-center-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

%flex-horiz-center {
  display: flex;
  justify-content: center;
}

%flex-horiz-center-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

%flex-vert-center {
  display: flex;
  align-items: center;
}

%flex-vert-start {
  display: flex;
  align-items: flex-start;
}

%flex-vert-end {
  display: flex;
  align-items: flex-end;
}

%flex-vert-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

%flex-horiz-away-vert-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

%flex-horiz-away {
  display: flex;
  justify-content: space-between;
}

%flex-horiz-end-vert-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
%flex-horiz-end {
  display: flex;
  justify-content: flex-end;
}
%flex-horiz-end-vert-center {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

%flex-wrap {
  flex-wrap: wrap;
}
// Flex direction (Firefox needs vendor prefix)
@mixin flex-direction($value: row) {
  -moz-flex-direction: $value;
  flex-direction: $value;
}

// IE11 fix for min height parent with flex align center
%ie11-min-h-flex-center-fix {
  content: '';
  display: block;
  min-height: inherit;
  font-size: 0;
}

%user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

%touch-tap-none {
  -webkit-tap-highlight-color: transparent;
}

%absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
%truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
%no-events-hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

%all-events-shown {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
%visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
//grid
%grid {
  display: grid;
  display: -ms-grid;
}
@mixin hideNoAction($value) {
  @if ($value == 0) {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  } @else {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

//merge changes

%dotStyle {
  content: '';
  background-color: $orange;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
%inputTextMerge {
  &.merge--input-text {
    color: $orange;
  }
}
%inputProcessCard {
  .gig-checkbox-card-checkbox ~ .gig-checkbox-card-wrapper {
    border: 1px solid $theme-border-shade-1;
    box-shadow: none;
    .gig-workflow-title {
      color: $theme-text-disabled;
    }
  }
}
