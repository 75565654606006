.tooltipContent {
  position: relative;
  overflow: unset;
}

.tooltipContent .tooltipText {
  visibility: hidden;
  background-color: #e7e8ea;
  color: #1b1e21;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  bottom: 125%;
  left: 50%;
  margin-left: -50%;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 12;
  word-wrap: break-word;
  max-width: 100%;
}

/* span > .tooltipContent .tooltipText {
  visibility: hidden;
  background-color: #1e6ec5;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 95%;
  margin-left: -27%;
  opacity: 0;
  transition: opacity 0.3s;
  word-wrap: break-word;
  max-width: 100%;
} */

/* span > .tooltipContent .tooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1e6ec5 transparent transparent transparent;
} */

.tooltipContent .tooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #e7e8ea transparent transparent transparent;
}

.tooltipContent:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}